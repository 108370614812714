<template>
  <div class="table">
    <el-table :data="tableData" border class="my-table" max-height="250">
      <el-table-column label="序号" type="index" width="50"> </el-table-column>
      <el-table-column label="班级" prop="class" width="150"
        ><template slot-scope="scope">
          <el-input
            type="text"
            v-if="scope.row.isEdit"
            v-model="scope.row.class"
          >
          </el-input>
          <span v-else>{{ scope.row.class }}</span>
        </template></el-table-column
      >
      <el-table-column align="right">
        <template slot="header">
          <el-button size="mini" type="primary" @click="handleAdd"
            >添加</el-button
          >
        </template>
        <template slot-scope="scope">
          <el-button
            :type="scope.row.isEdit ? 'success' : 'primary'"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
            >{{ scope.row.isEdit ? "保存" : "编辑" }}</el-button
          >
          <el-button
            size="mini"
            :type="scope.row.isEdit ? 'default' : 'danger'"
            @click="handleDelete(scope.$index, scope.row)"
            >{{ scope.row.isEdit ? "取消" : "删除" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    //获取班级
    getClass() {
      this.$http.get("/api/class/getclass").then((res) => {
        // console.log(res.data);
        this.tableData = res.data.data;
      });
    },
    //添加
    handleAdd() {
      let obj = {};
      this.tableData.unshift(obj);
      //添加编辑属性，使其一点添加就处于编辑状态
      this.$set(this.tableData[0], "isEdit", true);
      //添加标识判断是否是新增，如果是新增则在保存时发送post请求，否则发送update请求
      this.$set(this.tableData[0], "isAdd", true);
    },
    //添加班级axios
    addClass(row) {
      this.$http
        .post("/api/class/addclass", { class: row.class })
        .then((res) => {
          if (res.data && res.data.status == 200) {
            this.$message({ type: "success", message: res.data.msg });
            this.getClass();
          }
        });
    },
    //编辑
    handleEdit(index, row) {
      // 动态设置数据并通过这个数据判断显示方式
      if (row.isEdit) {
        // console.log("保存");
        // console.log(index, row);
        //如果是新增的
        if (row.isAdd) {
          this.addClass(row);
        } else {
          this.updateClass(row);
        }
        //点击保存时则删除isEdit
        //  this.$delete(obj,key)
        this.$delete(row, "isEdit");
      } else {
        // console.log(index, row);
        // console.log("编辑");
        //点击编辑时设置为true
        this.$set(row, "isEdit", true);
      }
    },
    updateClass(row) {
      this.$http
        .put("/api/class/updateclass", { class: row.class, id: row._id })
        .then((res) => {
          if (res.data && res.data.status == 200) {
            this.$message({ type: "success", message: res.data.msg });
            this.getClass();
          }
        });
    },
    //删除
    handleDelete(index, row) {
      if (row.isEdit) {
        // console.log("取消");
        this.$delete(row, "isEdit");
        if (row.isAdd) {
          this.tableData.shift();
        }
      } else {
        this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            //确定删除操作
            this.deleteClass(row);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    deleteClass(row) {
      this.$http
        .delete("/api/class/delclass", { params: { id: row._id } })
        .then((res) => {
          //   console.log(res.data);
          if (res.data && res.data.status == 200) {
            this.$message({ type: "success", message: res.data.msg });
            this.getClass();
          }
        });
    },
  },
  mounted() {
    this.getClass();
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 500px;
  margin: 0 auto;
  .my-table {
    margin-top: 50px;
  }
}
</style>
